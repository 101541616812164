<route>
{
  "meta": {
    "requiresAuth": true,
    "breadcrumb": "Create",
    "resource": "booking",
    "action": "write"
  }
}
</route>
<template>
  <div class="organisation"> </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'create',
  components: {},
  layout: 'AppFullPage',
};
</script>
